
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        























.panel-faq-categories {
  @include scrollbars(5px, $c-gray-lightest);

  max-width: 96rem;
  padding: 7rem 2rem;

  ::v-deep .faq-outer {
    background-color: $white;
  }

  ::v-deep .faq__title__icon {
    width: 3rem;
    height: 3rem;
    fill: $c-blue-dark;
  }

  ::v-deep .faq__title__headline {
    margin-bottom: 2rem;
    color: $c-blue-dark;
  }

  @include mq(l) {
    padding: 10rem 7rem;

    ::v-deep .faq__title__icon {
      width: 5.5rem;
      height: 5.5rem;
      fill: $c-blue-dark;
    }
  }
}
